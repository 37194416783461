import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Container from "../components/Container"
import { Layout } from "../components/Layout"
import { Seo } from "../components/Seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default function Contact({ data }) {
  const [hoveredImage, setHoveredImage] = useState(null)
  const [hoveredIndex, setHoveredIndex] = useState(null) // State to track hovered index
  const {
    job,
    contact: { name, country, mail, phone },
    behindTheScenes
  } = data.markdownRemark.frontmatter

  let isMobile = false

  // Check only if window is available (client-side rendering)
  if (typeof window !== "undefined") {
    isMobile = /Mobi/i.test(navigator.userAgent)
  }

  const handleMouseEnter = (image, index) => {
    if (!isMobile) {
      const img = getImage(image)
      setHoveredImage(img)
      setHoveredIndex(index) // Set the hovered index
    }
  }

  const handleMouseLeave = () => {
    setHoveredImage(null)
    setHoveredIndex(null) // Reset the hovered index
  }

  return (
    <Layout>
      <main className="flex items-center justify-center md:mt-[130px]">
        <Container>
          <section className="pt-[50px] pb-[100px]">
            <div className="mx-auto flex max-w-xl flex-col items-center justify-center text-center text-sm md:text-base">
              <ContactDetails job={job} name={name} country={country} mail={mail} phone={phone} />
              <div className="hidden lg:grid lg:grid-cols-[repeat(20,20px)] lg:grid-rows-[repeat(3,20px)] lg:gap-x-1.5 lg:gap-y-0">
                {behindTheScenes.map((item, index) => (
                  <div
                    key={index}
                    className={`w-5 h-5 bg-black ${
                      hoveredIndex !== null && hoveredIndex !== index ? "opacity-20" : "opacity-100"
                    }`}
                    role="button" // a11y fix"
                    tabIndex="0" // Made it focusable
                    onMouseOver={() => handleMouseEnter(item.image, index)}
                    onFocus={() => handleMouseEnter(item.image, index)}
                    onMouseOut={handleMouseLeave}
                    onBlur={handleMouseLeave}
                    style={{ gridArea: item.gridArea, transform: item.offSet }}
                    aria-label={item.alt} // Added aria-label for a11y
                  ></div>
                ))}
              </div>
            </div>
          </section>
          <div className="fixed top-1/2 left-1/2 min-w-[700px] max-w-[800px] -z-10 -translate-x-1/2 -translate-y-1/2">
            {hoveredImage && <GatsbyImage image={hoveredImage} alt="" className="opacity-90" />}
          </div>
        </Container>
      </main>
    </Layout>
  )
}

function ContactDetails({ job, name, country, mail, phone }) {
  return (
    <>
      <p className="my-6 font-bold uppercase">{job}</p>
      <address className="mb-12 flex flex-col items-center">
        <span className="capitalize">{name}</span>
        <span className="capitalize">{country}</span>
        <a
          href={`mailto:${mail}`}
          className="relative mx-auto h-max w-fit pb-1 after:absolute after:left-0 after:bottom-0 after:w-full after:origin-right after:scale-x-0 after:border-b-2 after:border-solid after:border-b-black after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65,0.05,0.36,1)] after:content-[''] hover:after:origin-left hover:after:scale-x-100"
        >
          {mail}
        </a>
        <a
          href={`tel:+316${phone}`}
          className="relative mx-auto h-max w-fit pb-1 after:absolute after:left-0 after:bottom-0 after:w-full after:origin-right after:scale-x-0 after:border-b-2 after:border-solid after:border-b-black after:transition-transform after:duration-300 after:ease-[cubic-bezier(0.65,0.05,0.36,1)] after:content-[''] hover:after:origin-left hover:after:scale-x-100"
        >{`+31 6 ${phone}`}</a>
      </address>
    </>
  )
}

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        job
        contact {
          name
          country
          mail
          phone
        }
        description
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        alt
        behindTheScenes {
          image {
            childImageSharp {
              gatsbyImageData(width: 1000, quality: 100)
            }
          }
          alt
          gridArea
          offSet
        }
      }
    }
  }
`

export const Head = ({ location, data }) => {
  const { title, description, siteUrl, author, jobTitle, mail, telephone } = useSiteMetadata()
  const metaDescription = data.markdownRemark.frontmatter.description || description
  const metaTitle = data.markdownRemark.frontmatter.title
  const metaImage = data.markdownRemark.frontmatter.image.publicURL

  return (
    <Seo title={`${metaTitle} | ${title}`} description={metaDescription} pathname={location.pathname} image={metaImage}>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "Person",
              "url": `${siteUrl}${location.pathname}`,
              "name": author,
              "image": `${siteUrl}${metaImage}`,
              "email": `mailto:${mail}`,
              "jobTitle": jobTitle,
              "telephone": telephone
            },
            {
              "@type": "ContactPage",
              "name": "Contact",
              "description": metaDescription,
              "url": `${siteUrl}${location.pathname}`,
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": `${siteUrl}${location.pathname}`
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": `${siteUrl}/`
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Contact",
                    "item": `${siteUrl}${location.pathname}`
                  }
                ]
              }
            }
          ]
        })}
      </script>
    </Seo>
  )
}
